import { render, staticRenderFns } from "./uOttawa1321Prelab2Q3.vue?vue&type=template&id=7dfa018b&"
import script from "./uOttawa1321Prelab2Q3.vue?vue&type=script&lang=js&"
export * from "./uOttawa1321Prelab2Q3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VBtnToggle,VCheckbox,VForm})
