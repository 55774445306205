<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        {{
          inputs.language === 'en'
            ? 'What are the properties of an ideal recrystallization solvent? Select all that apply.'
            : 'Quels sont les propriétés d’un solvant de recristallisation idéel ? Sélectionnez tous qui s’appliquent.'
        }}
      </p>

      <p
        v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
        :key="'pt-3-' + option.value"
        class="mb-0 mt-0"
      >
        <v-checkbox v-model="inputs.idealProperties" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mt-4 mb-0">
        <v-btn-toggle
          v-model="inputs.language"
          class="ml-2"
          size="small"
          dense="dense"
          rounded="rounded"
          mandatory
        >
          <v-btn :value="'en'">English</v-btn>
          <v-btn :value="'fr'">Français</v-btn>
        </v-btn-toggle>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab2Q3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        idealProperties: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'The solvent has a large solubility gradient with temperature',
          value: 'largeSolubilityGradient',
        },
        {text: 'The solvent is expensive', value: 'expensive'},
        {
          text: 'The solvent dissolves the product well at low temperatures',
          value: 'highSolubilityLowT',
        },
        {
          text: 'The product is insoluble or has low solubility at low temperature in the solvent',
          value: 'lowSolubilityLowT',
        },
        {text: 'The solvent dissolves impurities', value: 'dissolvedImpurities'},
        {
          text: 'The solvent dissolves the product well at high temperatures',
          value: 'highSolubilityHighT',
        },
        {text: 'The solvent reacts with the product', value: 'reactsWithProduct'},
        {text: 'The solvent does not react with the product', value: 'inertWithProduct'},
        {text: 'The solvent is inexpensive', value: 'inexpensive'},
        {text: 'The solvent is safe', value: 'safe'},
      ],
      optionsFr: [
        {
          text: 'Le solvant a un large gradient de solubilité avec la température',
          value: 'largeSolubilityGradient',
        },
        {text: 'Le solvant est dispendieun', value: 'expensive'},
        {
          text: 'Le solvant est capable de dissoudre le produit à des températures inférieures',
          value: 'highSolubilityLowT',
        },
        {
          text: 'Le produit est insoluble ou a une solubilité faible dans le solvant à des températures inférieures',
          value: 'lowSolubilityLowT',
        },
        {text: 'Le solvant dissout les impuretés', value: 'dissolvedImpurities'},
        {
          text: 'Le solvant est capable de dissoudre le produit à des températures supérieures',
          value: 'highSolubilityHighT',
        },
        {text: 'Le solvant réagit avec le produit', value: 'reactsWithProduct'},
        {text: 'Le solvant ne réagit pas avec le produit', value: 'inertWithProduct'},
        {text: 'Le solvant n’est pas dispendieux', value: 'inexpensive'},
        {text: 'Le solvent n’est pas dangereux', value: 'safe'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
